import { useEffect } from 'react';
import Mixpanel from 'services/tracking';
import styles from './AlreadyDiagnosed.module.scss';
import useScreenTracking from 'hooks/useScreenTracking';
import StoreRating from 'components/StoreRating';
import DownloadButton from 'components/DownloadButton';
import { useApi } from 'hooks/useApi';
import { useTranslation } from 'react-i18next';
import LogoutButton from 'components/LogoutButton';
import { TEST_ID, APOTEK_HJARTAT_ID } from '../../constants';

export default function AlreadyDiagnosed() {
  useScreenTracking('AlreadyDiagnosed');
  const { recommend } = useApi();
  const { t } = useTranslation();

  useEffect(() => {
    Mixpanel.trackLinks('#download-1', 'AlreadyDiagnosedDownloadButton1Clicked');
    Mixpanel.trackLinks('#download-2', 'AlreadyDiagnosedDownloadButton2Clicked');
  }, []);

  useEffect(() => {
    if (!TEST_ID) {
      return;
    }

    recommend('knownHypertension');
  }, [recommend]);

  if (TEST_ID === APOTEK_HJARTAT_ID) {
    return <ApotekHjartatSpecific />;
  }

  return (
    <div className={styles.wrapper}>
      <h1>{t('already_diagnosed.heading')}</h1>
      <p>{t('already_diagnosed.summary')}</p>
      <h2>{t('already_diagnosed.sub_heading_1')}</h2>
      <p>{t('already_diagnosed.paragraph_1_1')}</p>
      <p>{t('already_diagnosed.paragraph_1_2')}</p>
      <DownloadButton text={t('global.get_started')} id="download-1" />
      <div className="button-explainer margin-offset">
        <small>{t('disclaimer.download')}</small>
      </div>
      <h2>{t('already_diagnosed.sub_heading_2')}</h2>
      <ul>
        <li>{t('already_diagnosed.item_1')}</li>
        <li>{t('already_diagnosed.item_2')}</li>
        <li>{t('already_diagnosed.item_3')}</li>
        <li>{t('already_diagnosed.item_4')}</li>
      </ul>
      <h2>{t('already_diagnosed.sub_heading_3')}</h2>
      <p>{t('already_diagnosed.paragraph_3_1')}</p>
      <p>{t('already_diagnosed.paragraph_3_2')}</p>
      <ul>
        <li>{t('already_diagnosed.sub_item_1')}</li>
      </ul>
      <DownloadButton text={t('global.download_app')} id="download-2" />
      <StoreRating />
      <LogoutButton marginTop={20} />
    </div>
  );
}

const ApotekHjartatSpecific = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h1>{t('already_diagnosed_hjartat.heading')}</h1>
      <p>{t('already_diagnosed_hjartat.paragraph_1_1')}</p>
      <DownloadButton text={t('already_diagnosed_hjartat.download_cta')} id="download-1" />
      <h2>{t('already_diagnosed_hjartat.heading_2')}</h2>
      <p>{t('already_diagnosed_hjartat.paragraph_2_1')}</p>
      <DownloadButton
        text={t('already_diagnosed_hjartat.phonecall_cta')}
        id="download-1"
        url="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1UEOoQa6nJ4LiqP0wl84jlHw3h2CVydgTOW4V3-4UWK9UDj-YDu5IpPCs4-gw2vi30I6GeaLC1"
        variant="secondary"
      />
      <h2>{t('already_diagnosed_hjartat.heading_3')}</h2>
      <h3>{t('already_diagnosed_hjartat.heading_3_1')}</h3>
      <p>{t('already_diagnosed_hjartat.paragraph_3_1')}</p>
      <h3>{t('already_diagnosed_hjartat.heading_3_2')}</h3>
      <p>{t('already_diagnosed_hjartat.paragraph_3_2')}</p>
      <StoreRating />
      <LogoutButton marginTop={20} />
    </div>
  );
};
